// UI defaults for indicators

/*
Notes:

Measurements that appear in the patient timeline should be included in
measurementsOrderDefault(), to ensure they are listed in the appropriate order.

Format:

'indicatorid': {
  'timeline' : {
    'measurementsOrder': []
    'measurementsVisibility': []
  }
}

where:

measurementsOrder = Ordered list of measurements that should appear first in the timeline;
                    omitted measurements will follow the order specified in
                    measurementsOrderDefault()

measurementsVisibility = List of measurements that should be expanded by default. If not supplied,
                         measurementsOrder will be used instead. An empty list collapses all by
                         default.
*/

const indicatorDefaults = {

  'copd.exacerbation.rehab': { timeline: { measurementsOrder: ['FEV1', 'FVC'] } },

  'cvd.af.screening': { timeline: { measurementsOrder: ['pulseRhythm', 'BP'] } },

  'cvd.af.screeningAcute': { timeline: { measurementsOrder: ['pulseRhythm', 'BP'] } },

  'ckdAndProt.treatment.bp': { timeline: { measurementsOrder: ['BP', 'pulseRhythm', 'eGFR', 'ACR', 'Potassium', 'Sodium', 'Haemaglobin'] } },

  'ckdAndDm.treatment.bp': { timeline: { measurementsOrder: ['BP', 'pulseRhythm', 'eGFR', 'ACR', 'Potassium', 'Sodium', 'Haemaglobin'] } },

  'ckd.treatment.bp': { timeline: { measurementsOrder: ['BP', 'pulseRhythm', 'eGFR', 'ACR', 'Potassium', 'Sodium', 'Haemaglobin'] } },

  'ckd.diagnosis.staging': { timeline: { measurementsOrder: ['BP', 'pulseRhythm', 'eGFR', 'ACR', 'Potassium', 'Sodium', 'Haemaglobin'] } },

  'ckd.diagnosis.undiagnosed': { timeline: { measurementsOrder: ['BP', 'pulseRhythm', 'eGFR', 'ACR', 'Potassium', 'Sodium', 'Haemaglobin'] } },

  'ckd.diagnosis.monitoring': { timeline: { measurementsOrder: ['BP', 'pulseRhythm', 'eGFR', 'ACR', 'Potassium', 'Sodium', 'Haemaglobin'] } },

  'htn.undiagnosed.measures': { timeline: { measurementsOrder: ['BP', 'pulseRhythm', 'eGFR', 'ACR', 'Potassium', 'Sodium'] } },

  'htn.undiagnosed.med': { timeline: { measurementsOrder: ['BP', 'pulseRhythm', 'eGFR', 'ACR', 'Potassium', 'Sodium'] } },

  'htn.treatment.bp': { timeline: { measurementsOrder: ['BP', 'pulseRhythm', 'eGFR', 'ACR', 'Potassium', 'Sodium'] } },

  'cvd.stroke.outcome': { timeline: { measurementsOrder: ['BP', 'pulseRhythm'] } },

  'frailty.diagnosis.coding': { timeline: { measurementsOrder: ['BP', 'pulseRhythm', 'eGFR', 'ACR', 'FEV1', 'FVC', 'Potassium', 'Sodium', 'Haemaglobin'] } },

  'meds.dmard.monitor': {
    timeline: {
      measurementsOrder: ['Hb', 'MCV', 'WCC', 'Neutrophil', 'Lymphocyte', 'Eosinophil', 'ALP', 'ALT', 'GGT', 'AST', 'Bilirubin', 'Albumin', 'Sodium', 'Potassium', 'eGFR'],
      measurementsVisibility: ['Hb', 'WCC', 'ALP', 'ALT', 'eGFR'],
    },
  },

  'aki.bp.3months': {
    timeline: {
      measurementsOrder: ['Sodium', 'Potassium', 'Creatinine', 'eGFR', 'ACR'],
      measurementsVisibility: ['Potassium', 'Creatinine', 'eGFR', 'ACR'],
    },
  },

  'aki.creatininemonitoring.3months': {
    timeline: {
      measurementsOrder: ['Sodium', 'Potassium', 'Creatinine', 'eGFR', 'ACR'],
      measurementsVisibility: ['Potassium', 'Creatinine', 'eGFR', 'ACR'],
    },
  },

  'aki.proteinuriamonitoring.3months': {
    timeline: {
      measurementsOrder: ['Sodium', 'Potassium', 'Creatinine', 'eGFR', 'ACR'],
      measurementsVisibility: ['Potassium', 'Creatinine', 'eGFR', 'ACR'],
    },
  },

  'aki.medreview.3months': {
    timeline: {
      measurementsOrder: ['Sodium', 'Potassium', 'Creatinine', 'eGFR', 'ACR'],
      measurementsVisibility: ['Potassium', 'Creatinine', 'eGFR', 'ACR'],
    },
  },

  'aki.followup.writteninfo': {
    timeline: {
      measurementsOrder: ['Sodium', 'Potassium', 'Creatinine', 'eGFR', 'ACR'],
      measurementsVisibility: ['Potassium', 'Creatinine', 'eGFR', 'ACR'],
    },
  },

  'aki.diagnosis.undiagnosed': {
    timeline: {
      measurementsOrder: ['Sodium', 'Potassium', 'Creatinine', 'eGFR', 'ACR'],
      measurementsVisibility: ['Potassium', 'Creatinine', 'eGFR', 'ACR'],
    },
  },

  'stroke.post.bp2weeks': { timeline: { measurementsOrder: ['BP'] } },

  'adapt.lymphoma.followup': { timeline: { measurementsOrder: ['BP', 'Hb', 'Creatinine', 'eGFR', 'ALP', 'ALT', 'TSH', 'cholesterol', 'triglycerides', 'cholesterolHDLRatio'] } },

  'doac.monitor.dose': { timeline: { measurementsOrder: ['Creatinine', 'weight'] } },

  'copd.med.review': { timeline: { measurementsOrder: ['FEV1', 'FVC'] } },

  'copd.flu.vaccine': { timeline: { measurementsOrder: ['FEV1', 'FVC'] } },

  'copd.pneumococcal.vaccine': { timeline: { measurementsOrder: ['FEV1', 'FVC'] } },

  'copd.inhaler.check': { timeline: { measurementsOrder: ['FEV1', 'FVC'] } },

  'copd.selfmanagement.plan': { timeline: { measurementsOrder: ['FEV1', 'FVC'] } },

  'copd.smokingcessation.referral': { timeline: { measurementsOrder: ['FEV1', 'FVC'] } },

  'copd.pulmonaryrehab.referral': { timeline: { measurementsOrder: ['FEV1', 'FVC'] } },

  'af.warfarin.ttr': { timeline: { measurementsOrder: ['INR'] } },

};

module.exports = {

  // Baseline order for measurements appearing in the patient timeline
  measurementsOrderDefault: () => {
    const measurementsOrder = [
      'BP', 'pulseRhythm', 'strokeHosp', 'FEV1',
      'Hb', 'MCV', 'WCC', 'Neutrophil', 'Lymphocyte', 'Eosinophil',
      'Sodium', 'Potassium', 'Creatinine', 'eGFR', 'ACR',
      'ALP', 'ALT', 'GGT', 'AST', 'Bilirubin', 'Albumin',
      'TSH', 'cholesterol', 'triglycerides', 'cholesterolHDLRatio',
      'weight', 'INR',
    ];
    return measurementsOrder;
  },

  // The order measurements should appear in the patient timeline for a given indicator
  measurementsOrderForIndicator: (indicatorId) => {
    if (indicatorDefaults[indicatorId] === undefined ||
      indicatorDefaults[indicatorId].timeline === undefined) return [];
    if (indicatorDefaults[indicatorId].timeline.measurementsOrder === undefined) return [];
    return indicatorDefaults[indicatorId].timeline.measurementsOrder;
  },

  // Which measurements should be expanded in the patient timeline by default for a given indicator
  // defaults to measurementsOrder unless explicitly set
  measurementsVisibilityForIndicator: (indicatorId) => {
    if (indicatorDefaults[indicatorId] === undefined ||
      indicatorDefaults[indicatorId].timeline === undefined) return [];
    if (indicatorDefaults[indicatorId].timeline.measurementsVisibility === undefined &&
      indicatorDefaults[indicatorId].timeline.measurementsOrder === undefined) return [];
    if (indicatorDefaults[indicatorId].timeline.measurementsVisibility !== undefined) {
      return indicatorDefaults[indicatorId].timeline.measurementsVisibility;
    }
    return indicatorDefaults[indicatorId].timeline.measurementsOrder;
  },

};
